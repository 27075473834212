import React, { useEffect, useState } from 'react';
import ApiManagedProfiles, { ManagedProfileFullDTO } from '../../../../api/api-managed-profiles';
import { Card, Col, Container, ErrorMessage, Row, Spinner, Title } from '../../../style';
import WMSServerAddNew from '../../WMS/Server/wms-server-add-new';
import ProfileCogUpload from '../Listings/profile-cog-upload';
import ProfileExternalCogAdd from '../Listings/profile-external-cog-add';
import ProfileExternalTileLayerAdd from '../Listings/profile-external-tile-layer-add';
import ProfileTileLayerUpload from '../Listings/profile-tile-layer-upload';
import ProfileDetails from './profile-details';
import ProfileListings from './ProfileListings/profile-listings';
import ProfileWordFileUpload from '../Listings/profile-worldfile-upload';

const ProfilePage = (props) => {
    const id: string = props.match.params.id;
    const [profile, setProfile] = useState<ManagedProfileFullDTO | undefined>();
    const [error, setError] = useState<Error | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadProfile = async (id: string) => {
        setIsLoading(true);
        try {
            const profile = await ApiManagedProfiles.getProfile(id);
            setProfile(profile);
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        loadProfile(id);
    }, [id]);

    if ((!profile && !error) || isLoading) {
        return (
            <Container>
                <Spinner />
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Title>Manage Profile</Title>
                <Row>
                    <Col>
                        <Card>
                            <ErrorMessage>{error?.message || 'Something has gone wrong!'}</ErrorMessage>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container>
            <Title>Manage Profile</Title>
            <React.Fragment>
                <ProfileDetails profile={profile} onUpdate={(updatedProfile) => setProfile(updatedProfile)} />
                <Row>
                    <Card>
                        <Row>
                            <Col>
                                <WMSServerAddNew buttonText="Add WMS/WMTS" userId={profile.user.userId} />
                                <ProfileExternalTileLayerAdd userId={profile.user.userId} />
                                <ProfileExternalCogAdd userId={profile.user.userId} />
                                <ProfileTileLayerUpload userId={profile.user.userId} />
                                <ProfileCogUpload userId={profile.user.userId} />
                                <ProfileWordFileUpload userId={profile.user.userId} />
                            </Col>
                        </Row>
                    </Card>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <ProfileListings profile={profile} />
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        </Container>
    );
};

export default ProfilePage;
