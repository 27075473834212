import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AttachedListingDTO } from '../../../../../api/api-managed-profiles';
import { push } from 'connected-react-router';
import store from '../../../../../store/store';
import ProfileListingsBulkUpdateActions from './profile-listings-bulk-update-actions';
import { SortBy, SortOrder } from './profile-listings';
import ProfileListingsGenericTable from './profile-listings-generic-table';

interface ProfileListingsTableProps {
    userId: string;
    listings: AttachedListingDTO[];
    sortBy: SortBy;
    sortOrder: SortOrder;
    textFilter: string;
}

const COLS_LISTINGS = [
    {
        dataField: 'id',
        text: 'ID',
        headerStyle: () => {
            return {
                width: '40px',
            };
        },
    },
    {
        dataField: 'listingType',
        text: 'Listing Type',
    },
    {
        dataField: 'title',
        text: 'Title',
    },
    {
        dataField: 'processing',
        text: 'Processing',
        headerStyle: () => {
            return {
                width: '100px',
            };
        },
        formatter: (_, row: AttachedListingDTO) => {
            if (row.processing) {
                return <Checkmark className="fa fa-check" />;
            } else {
                return <React.Fragment />;
            }
        },
    },
    {
        dataField: 'public',
        text: 'Available in Soar',
        headerStyle: () => {
            return {
                width: '140px',
            };
        },
        formatter: (_, row: AttachedListingDTO) => {
            if (row.reviewSoar == 'APPROVED') {
                return <Checkmark className="fa fa-check" />;
            } else {
                return <React.Fragment />;
            }
        },
    },
];

const ProfileListingsTable = ({ userId, listings, textFilter, sortBy, sortOrder }: ProfileListingsTableProps) => {
    const [selectedListings, setSelectedListings] = useState<Set<number>>(new Set());
    const [listingsFiltered, setListingsFiltered] = useState<AttachedListingDTO[]>(listings);

    useEffect(() => {
        const handleFilter = () => {
            const textFilterRegEx = new RegExp(textFilter, 'img');
            setListingsFiltered(
                listings
                    .filter(
                        (listing) =>
                            textFilterRegEx.test(listing.title) ||
                            textFilterRegEx.test(listing.listingType) ||
                            textFilterRegEx.test(listing.id.toString())
                    )
                    .sort((a, b) => {
                        if (sortBy === 'id') {
                            return sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
                        } else if (sortBy === 'title') {
                            return sortOrder === 'asc'
                                ? a.title.localeCompare(b.title)
                                : b.title.localeCompare(a.title);
                        } else {
                            return 0;
                        }
                    })
            );
        };
        handleFilter();
    }, [listings, setListingsFiltered, sortBy, sortOrder, textFilter]);

    const onClickRowListing = {
        onClick: (_e, row: AttachedListingDTO) => {
            const siblings = listingsFiltered.map((l) => l.id).join(',');
            store.dispatch(push(`/profiles/${userId}/listings/${row.id}?siblings=${siblings}`));
        },
        onAuxClick: (_e, row: AttachedListingDTO) => {
            const siblings = listingsFiltered.map((l) => l.id).join(',');
            const win = window.open(`/profiles/${userId}/listings/${row.id}?siblings=${siblings}`, '_blank');
            if (!win) {
                win.focus();
            }
        },
    };

    const handleSelectListing = (row: AttachedListingDTO, isSelected: boolean) => {
        setSelectedListings((prevSelected) => {
            const newSelected = new Set(prevSelected);
            if (isSelected) {
                newSelected.add(row.id);
            } else {
                newSelected.delete(row.id);
            }
            return newSelected;
        });
    };

    const handleSelectAllListings = (isSelected: boolean, rows: AttachedListingDTO[]) => {
        if (isSelected) {
            const allIds = rows.map((row) => row.id);
            setSelectedListings(new Set(allIds));
        } else {
            setSelectedListings(new Set());
        }
    };

    if (!listings?.length) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            <ProfileListingsGenericTable
                subTitle={`Total of ${listings.length} attached listings`}
                data={listingsFiltered}
                columns={COLS_LISTINGS}
                onClickRowEvent={onClickRowListing}
                selectRow={{
                    mode: 'checkbox',
                    clickToSelect: false,
                    onSelect: handleSelectListing,
                    onSelectAll: handleSelectAllListings,
                }}
            />
            {selectedListings.size > 0 ? (
                <ProfileListingsBulkUpdateActions userId={userId} selectedListings={selectedListings} />
            ) : null}
        </React.Fragment>
    );
};

export default ProfileListingsTable;

const Checkmark = styled.i`
    color: green;
`;
