import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Divider, Subtitle, Table, TableWrapper } from '../../../../style';
import {
    AttachedListingDTO,
    AttachedSTACCatalogDTO,
    AttachedWmsServerDTO,
} from '../../../../../api/api-managed-profiles';

interface Columns {
    dataField: string;
    text: string;
    headerStyle?: () => any;
    style?: () => any;
    formatter?: (_: any, row: any) => any;
}

interface OnClickRowEvent {
    onClick: (_: any, row: any) => void;
}

type AttachedDataDTO = AttachedSTACCatalogDTO[] | AttachedWmsServerDTO[] | AttachedListingDTO[];

interface ProfileListingsGenericTableProps {
    subTitle: string;
    data: AttachedDataDTO;
    columns: Columns[];
    onClickRowEvent: OnClickRowEvent;
    selectRow?: any;
    divider?: boolean;
}

const paginationOptions = {
    sizePerPageList: [
        {
            text: '10',
            value: 10,
        },
        {
            text: '25',
            value: 25,
        },
        {
            text: '50',
            value: 50,
        },
        {
            text: '100',
            value: 100,
        },
        {
            text: '1000',
            value: 1000,
        },
    ],
};

const ProfileListingsGenericTable = ({
    subTitle,
    data,
    columns,
    onClickRowEvent,
    selectRow,
    divider,
}: ProfileListingsGenericTableProps) => {
    return (
        <React.Fragment>
            <Subtitle>{subTitle}</Subtitle>
            <ToolkitProvider keyField="id" data={data} columns={columns}>
                {(props) => (
                    <React.Fragment>
                        <TableWrapper>
                            <Table
                                {...props.baseProps}
                                rowEvents={onClickRowEvent}
                                pagination={paginationFactory(paginationOptions)}
                                selectRow={selectRow}
                            />
                        </TableWrapper>
                    </React.Fragment>
                )}
            </ToolkitProvider>
            {divider ? <Divider /> : null}
        </React.Fragment>
    );
};

export default ProfileListingsGenericTable;
