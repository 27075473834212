interface Error {
    code?: string;
    message?: string;
    name?: string;
    requestId?: string;
    type?: string;
}

export const handleErrorMessage = (error: Error | string): string => {
    let errorMessage = 'Something has gone wrong. Please try again later.';
    if (typeof error === 'object' && error?.message) {
        errorMessage = error.message;
    } else {
        errorMessage = error as string;
    }
    return errorMessage;
};
