import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ApiListings from '../../api/api-listings';
import { ListingDTO, Review, ReviewItemDTO, ReviewRejectionReason, ReviewRejectionCategory } from '../../api/model';
import config from '../../config';
import { actionUpdateFeatured } from '../../store/AppStore/actions';
import { selectFeatured } from '../../store/AppStore/selectors';
import ButtonsPanel from '../Shared/buttons-panel';
import ErrorPanel from '../Shared/error-panel';
import { Button, Input, InvisibleCard, Spinner } from '../style';
import RejectReason from './reject-reason';
import { featuredCategories } from '../../api/api-admin';

interface ReviewActionsProps {
    listing: ListingDTO;
    onReviewResult: (review: ReviewItemDTO) => void;
    onReviewStart?: () => void;
    handleSkipped?: () => void;
}

const ReviewActions = (props: ReviewActionsProps) => {
    const { listing } = props;
    const [processing, setProcessing] = useState(false);

    const [isAskingForRejectionReason, setIsAskingForRejectionReason] = useState(false);
    const [error, setError] = useState<string>(undefined);
    const [category, setCategory] = useState<string>('');
    const [rejectReasons, setRejectReasons] = useState<ReviewRejectionReason[]>([]);
    const [rejectReasonsError, setRejectReasonsError] = useState<string | undefined>(
        rejectReasons.length === 0 ? 'No rejection reason selected' : undefined
    );

    const featured = useSelector(selectFeatured);

    const dispatch = useDispatch();

    const submitReview = async (review: ReviewItemDTO) => {
        if (props.onReviewStart) {
            props.onReviewStart();
        }
        setProcessing(true);
        let res: ReviewItemDTO;
        try {
            res = await ApiListings.reviewListing(review);
            res.featured = review.featured;
            res.feedback = review.feedback;
            res.rejectionReasons = review.rejectionReasons;
            res.priority = review.priority;
        } catch (err) {
            res = { ...review };
            res.error = err.toString();
        }
        setProcessing(false);
        props.onReviewResult(review);
        setError(res.error);
    };

    const submitApprovedReview = () => {
        const review: ReviewItemDTO = {
            id: listing.id,
            review: Review.APPROVED,
            featured: 'false',
            priority: 50,
        };

        submitReview(review);
    };

    const submitApprovedAndFeaturedReview = () => {
        const maxFeatureByCategory = featuredCategories.find((featuredCategory) => {
            if (featuredCategory.value !== category) return '';
            return featuredCategory;
        });

        const count = maxFeatureByCategory.count || config.FEATURED_CATEGORY_MAP_COUNT;
        if (count.toString() <= featured?.length.toString()) {
            toast.error(`You already have ${count} maps featured, Please remove some before adding more!`);
            setCategory('');
            return;
        }

        const review: ReviewItemDTO = {
            id: listing.id,
            review: Review.APPROVED,
            featured: 'false',
            priority: 50,
        };

        submitReview(review).finally(() => {
            dispatch(actionUpdateFeatured(listing.id, category, listing.featured, true));
        });
    };

    const submitRejectReview = () => {
        if (rejectReasonsError) {
            toast.error(rejectReasonsError);
            return;
        }

        if (rejectReasons) {
            const review: ReviewItemDTO = {
                id: listing.id,
                review: Review.REJECTED,
                rejectionReasons: rejectReasons.map(
                    (rejectReason) =>
                        ({
                            reason: rejectReason.reason,
                            feedback: rejectReason.feedback,
                            blogLink: rejectReason.blogLink,
                        } as ReviewRejectionReason)
                ),
                priority: 50,
            };

            submitReview(review);
        }
    };

    const submitReject = () => {
        const review: ReviewItemDTO = {
            id: listing.id,
            review: Review.REJECTED,
            // https://github.com/SoarEarth/soar-admin-client/issues/177#issuecomment-2533698580
            // Backend validation expects the rejectionReasons, so we provide a blank one
            rejectionReasons: [
                {
                    reason: 'Rejection reason not specified',
                    feedback: '',
                    blogLink: '',
                } as ReviewRejectionReason,
            ],
            priority: 50,
        };

        submitReview(review);
    };

    const setRejectionReason = (reasons: ReviewRejectionReason[]) => {
        setRejectReasonsError(undefined);
        setRejectReasons(reasons);

        if (reasons.length === 0) {
            setRejectReasonsError('No rejection reason selected');
        }
        if (reasons.find((r) => r.category === ReviewRejectionCategory.OTHER && r.feedback === '')) {
            setRejectReasonsError('No rejection reason specified');
        }
    };

    if (isAskingForRejectionReason) {
        return (
            <InvisibleCard>
                <RejectReason onRejectionReasonUpdated={setRejectionReason} />
                <ButtonsPanel>
                    <Button disabled={processing} color="danger" onClick={() => submitReject()}>
                        Reject only
                    </Button>
                    <Button disabled={processing} color="danger" onClick={() => submitRejectReview()}>
                        Reject and send feedback
                    </Button>
                    <Button color="warning" onClick={() => setIsAskingForRejectionReason(false)}>
                        Cancel your review
                    </Button>
                </ButtonsPanel>
            </InvisibleCard>
        );
    }

    return (
        <React.Fragment>
            {error && <ErrorPanel>{error}</ErrorPanel>}
            <ButtonControls>
                {featuredCategories ? (
                    <FeaturedInput
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        type="select"
                        name="category"
                        id="category"
                    >
                        <option value="">Add To Featured</option>
                        {featuredCategories.map((cat, i) => {
                            return <option key={`${cat}-${i}`} value={cat.value} label={cat.label} />;
                        })}
                    </FeaturedInput>
                ) : (
                    <Spinner />
                )}
                <Button
                    disabled={processing}
                    onClick={category ? submitApprovedAndFeaturedReview : submitApprovedReview}
                >
                    {`Approve ${category ? '& feature' : ''}`}
                </Button>
                <Button disabled={processing} color="danger" onClick={() => setIsAskingForRejectionReason(true)}>
                    Reject
                </Button>
                {props.handleSkipped && <Button onClick={() => props.handleSkipped()}>Skip</Button>}
            </ButtonControls>
        </React.Fragment>
    );
};

export default ReviewActions;

const ButtonControls = styled(ButtonsPanel)`
    text-align: center;
`;

const FeaturedInput = styled(Input)`
    width: auto;
    max-height: 38px;
    margin-top: 5px;
    margin-right: 4px;
`;
