import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectTotalMapInteractions } from '../../../store/ReportsStore/selectors';
import { Button, Card, Container, Subtitle, Title } from '../../style';
import ChartPie from './chart-pie';
import Data from './Data/data';
import ChartsInterval from './Intervals/charts-interval';
import MapViews from './map-views';
import AnalyticsMap from './Map/analytics-map';
import Products from './producs';
import SearchedCountries from './searched-countries';

export enum DashboardType {
    Heatmap,
    Data,
    Intervals,
    PieChart,
    SearchedCountries,
    MapViews,
    Products,
}

const DataLake = () => {
    const total = useSelector(selectTotalMapInteractions);
    const [dashboard, setDashboard] = useState<DashboardType>(DashboardType.Data);

    return (
        <Container>
            <Title>Data Lake</Title>
            <DataLakeSubtitle>
                Total map interactions: <b>{total ? total.toLocaleString() : null}</b>
            </DataLakeSubtitle>
            <Card>
                <ButtonContainer>
                    <Button active={dashboard === DashboardType.Data} onClick={() => setDashboard(DashboardType.Data)}>
                        Data
                    </Button>

                    <Button
                        active={dashboard === DashboardType.Heatmap}
                        onClick={() => setDashboard(DashboardType.Heatmap)}
                    >
                        Heatmap
                    </Button>
                    <Button
                        active={dashboard === DashboardType.Intervals}
                        onClick={() => setDashboard(DashboardType.Intervals)}
                    >
                        Graphs
                    </Button>
                    <Button
                        active={dashboard === DashboardType.PieChart}
                        onClick={() => setDashboard(DashboardType.PieChart)}
                    >
                        By Products
                    </Button>
                    <Button
                        active={dashboard === DashboardType.SearchedCountries}
                        onClick={() => setDashboard(DashboardType.SearchedCountries)}
                    >
                        Searched Countries
                    </Button>
                    <Button
                        active={dashboard === DashboardType.MapViews}
                        onClick={() => setDashboard(DashboardType.MapViews)}
                    >
                        Map Views
                    </Button>
                    <Button
                        active={dashboard === DashboardType.Products}
                        onClick={() => setDashboard(DashboardType.Products)}
                    >
                        Products
                    </Button>
                </ButtonContainer>
            </Card>
            <React.Fragment>
                {dashboard === DashboardType.Data ? <Data /> : null}
                {dashboard === DashboardType.Heatmap ? <AnalyticsMap /> : null}
                {dashboard === DashboardType.Intervals ? <ChartsInterval /> : null}
                {dashboard === DashboardType.PieChart ? <ChartPie /> : null}
                {dashboard === DashboardType.SearchedCountries ? <SearchedCountries /> : null}
                {dashboard === DashboardType.MapViews ? <MapViews /> : null}
                {dashboard === DashboardType.Products ? <Products /> : null}
            </React.Fragment>
        </Container>
    );
};

export default DataLake;

const ButtonContainer = styled.div`
    text-align: center;
    margin: 8px;

    button {
        margin: 8px;
    }
`;

const DataLakeSubtitle = styled(Subtitle)`
    margin-left: 10px;
`;
