const test = {
    API_URL: 'https://api-admin.soar-test.earth',
    DOMAIN: 'admin.soar-test.earth',
    HTTPS: true,
    FC_URL: 'https://5158124120825140.ap-southeast-1.fc.aliyuncs.com/2016-08-15/proxy/soar-fc-test',
    CLIENT_URL: 'https://soar-test.earth',
    CLIENT_DOMAIN: 'soar-test.earth',
    COGNITO_REGION: 'ap-southeast-1',
    COGNITO_CLIENT_ID: 'q8qui1f6s62rm3lnpqk495g2v',
    EMBED_MAP_URL: 'https://embed-map-viewer.soar.earth',
    SUPER_MAP_PROJECT_ID: 1,
    SUPER_MAP_DOMAIN: 'soar-test.earth',
    SENTRY_URL: 'https://73077aab74274a55a1a30ec72c61b97c@o374708.ingest.sentry.io/5264082',
    UPLOAD_QUEUE_SIZE: 6,
    TILE_ENGINE_URL: 'https://tile-test.soar.earth',
    FEATURED_HOTTEST_MAP_COUNT: 6,
    FEATURED_CATEGORY_MAP_COUNT: 4,
    FEATURED_NEWS_MAP_COUNT: 1,
};

const prod = {
    API_URL: 'https://api-admin.soar.earth',
    DOMAIN: 'admin.soar.earth',
    HTTPS: true,
    FC_URL: 'https://5158124120825140.ap-southeast-1.fc.aliyuncs.com/2016-08-15/proxy/soar-fc-prod',
    CLIENT_URL: 'https://soar.earth',
    CLIENT_DOMAIN: 'soar.earth',
    COGNITO_REGION: 'ap-southeast-1',
    COGNITO_CLIENT_ID: '6bungbuurc06d50gpb4kumgk9v',
    EMBED_MAP_URL: 'https://embed-map-viewer.soar.earth',
    SUPER_MAP_PROJECT_ID: 1,
    SUPER_MAP_DOMAIN: 'soar.earth',
    SENTRY_URL: 'https://8e3c85f2c80b4489accebe46889c6901@o374708.ingest.sentry.io/5264085',
    UPLOAD_QUEUE_SIZE: 6,
    TILE_ENGINE_URL: 'https://shared-tile.soar.earth',
    FEATURED_HOTTEST_MAP_COUNT: 6,
    FEATURED_CATEGORY_MAP_COUNT: 4,
    FEATURED_NEWS_MAP_COUNT: 1,
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : test;

export default {
    // Add common config values here
    ...config,
    maps: {
        DARK_GRAY_BASE_MAP:
            'https://server.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}.png',
        OSM_STREET: 'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
        GOOGLE_SATELLITE: 'https://mt.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
        GOOGLE_STREET: 'https://mt.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        GOOGLE_HYBRID: 'https://mt.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
        OSM_TOPO: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
        OSM_HILLSHADE: 'https://tiles.wmflabs.org/hillshading/{z}/{x}/{y}.png',
        MAPBOX_SATELLITE:
            'https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiY2hyaXNsb3dlIiwiYSI6ImYzZmI2OWM3MDgyNWQ0MTdmZTRkYWM5MjVlN2JlY2Q3In0.3aXTSJOkgeC_obQ6TDmlcw',
        MODIS_TEMPLATE:
            'https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/MODIS_Terra_CorrectedReflectance_TrueColor/default/{date}/GoogleMapsCompatible_Level9/{z}/{y}/{x}.jpg',
        HIMAWARI: 'http://d30f2i1nzx9v71.cloudfront.net/himawari/IDE00436/Himawari/{date}/{z}/{x}/{-y}.jpg',
    },
};
