import { Base64 } from 'js-base64';
import L, { Icon } from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { LayersControl, TileLayer } from 'react-leaflet';
import styled from 'styled-components';
import * as Wellknown from 'wellknown';
import GeoUtil from '../../lib/geo-util';
import LeafletMap from '../MapView/leaflet-map';
import { Button } from '../style';
import LeafletBaseMaps from './leaflet-basemaps';
import OpacitySlider from './opacity-slider';

const { Overlay } = LayersControl;

interface Props {
    locationWKT: string;
    base64Kml?: string;
    downloadButtons?: boolean;
    zoom?: number;
    tileUrl?: string;
    product?: string;
    fillOpacity?: number;
}

const DEFAULT_IMAGE_ZOOM = 15;

const customMarker = new Icon({
    iconUrl: 'assets/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
});

const SingleFeatureMap = (props: Props) => {
    const { locationWKT, zoom, tileUrl, downloadButtons, base64Kml, product, fillOpacity } = props;
    const leafletMapRef = useRef(null);
    const [mapReady, setMapReady] = useState(false);
    const [opacity, setOpacity] = useState(1.0);

    const handleMapReady = () => {
        requestAnimationFrame(() => {
            setMapReady(true);
        });
    };

    useEffect(() => {
        console.log('single-feature-map.tsx: useEffect()', locationWKT, leafletMapRef?.current);
        if (locationWKT && leafletMapRef?.current) {
            const leafletMap = leafletMapRef.current;
            const geoJson = Wellknown.parse(locationWKT);
            const locationLayer = L.geoJSON(geoJson, {
                style: { fillOpacity: fillOpacity || 0 },
                pointToLayer: (_, latlng) => {
                    return L.marker(latlng, { icon: customMarker });
                },
            }).addTo(leafletMap);
            leafletMap.fitBounds(locationLayer.getBounds(), { padding: [50, 50] });
            if (product?.toUpperCase() === 'IMAGE') {
                leafletMap.setZoom(DEFAULT_IMAGE_ZOOM);
            } else if (zoom) {
                leafletMap.setZoom(zoom);
            }
        }
    }, [locationWKT, product, zoom, mapReady, fillOpacity]);

    const downloadShp = () => {
        try {
            GeoUtil.downloadAoiAsShapefile(locationWKT, 'geometry');
        } catch (err) {
            //no-empty
        }
    };

    const downloadKml = () => {
        const element = document.createElement('a');
        const kml = Base64.decode(base64Kml);
        const file = new Blob([kml], { type: 'application/vnd.google-earth.kml+xml' });
        element.href = URL.createObjectURL(file);
        element.download = 'AOI_geometry.kml';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    return (
        <SingleMapViewContainer className="single-feature-map">
            <LeafletMap mapRef={leafletMapRef} fullscreenControl={true} mapReady={() => handleMapReady()}>
                <LayersControl position="topright">
                    <LeafletBaseMaps />

                    {tileUrl && (
                        <Overlay name={'Preview'} checked={true}>
                            <TileLayer
                                tms={true}
                                attribution=""
                                url={tileUrl}
                                opacity={opacity}
                                maxZoom={28}
                                maxNativeZoom={28}
                            />
                        </Overlay>
                    )}
                </LayersControl>
            </LeafletMap>
            <div style={{ height: '20px', width: '100%', margin: '0 auto' }}>
                <OpacitySlider opacity={opacity} onChange={setOpacity} />
            </div>
            {downloadButtons && (
                <SingleMapViewDownload>
                    <Button color="link" onClick={downloadShp}>
                        Download AOI SHP
                    </Button>
                    {base64Kml && (
                        <Button color="link" onClick={downloadKml}>
                            Download AOI KML
                        </Button>
                    )}
                </SingleMapViewDownload>
            )}
        </SingleMapViewContainer>
    );
};

export default SingleFeatureMap;

const SingleMapViewContainer = styled.div`
    width: 100%;
    height: 400px;
    margin-bottom: 40px;
`;

const SingleMapViewDownload = styled.div`
    text-align: center;
`;
