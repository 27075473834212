import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AttachedListingDTO } from '../../../../../api/api-managed-profiles';
import { push } from 'connected-react-router';
import store from '../../../../../store/store';
import ProfileListingsWmsOrphanedUpdateActions from './profile-listings-wms-orphaned-update-actions';
import { SortOrder } from 'react-bootstrap-table-next';
import { SortBy } from './profile-listings';
import ProfileListingsGenericTable from './profile-listings-generic-table';

interface ProfileListingsOrphanedTableProps {
    userId: string;
    wmsOrphanedListings: AttachedListingDTO[];
    sortBy: SortBy;
    sortOrder: SortOrder;
    textFilter: string;
}

const COLS_LISTINGS = [
    {
        dataField: 'id',
        text: 'ID',
        headerStyle: () => {
            return {
                width: '40px',
            };
        },
    },
    {
        dataField: 'listingType',
        text: 'Listing Type',
    },
    {
        dataField: 'title',
        text: 'Title',
    },
    {
        dataField: 'processing',
        text: 'Processing',
        headerStyle: () => {
            return {
                width: '100px',
            };
        },
        formatter: (_, row: AttachedListingDTO) => {
            if (row.processing) {
                return <Checkmark className="fa fa-check" />;
            } else {
                return <React.Fragment />;
            }
        },
    },
    {
        dataField: 'public',
        text: 'Available in Soar',
        headerStyle: () => {
            return {
                width: '140px',
            };
        },
        formatter: (_, row: AttachedListingDTO) => {
            if (row.reviewSoar == 'APPROVED') {
                return <Checkmark className="fa fa-check" />;
            } else {
                return <React.Fragment />;
            }
        },
    },
];

const ProfileListingsOrphanedTable = ({
    userId,
    wmsOrphanedListings,
    textFilter,
    sortBy,
    sortOrder,
}: ProfileListingsOrphanedTableProps) => {
    const [wmsOrphanedListingsFiltered, setWmsOrphanedListingsFiltered] =
        useState<AttachedListingDTO[]>(wmsOrphanedListings);
    const [selectedwmsOrphanedListings, setSelectedwmsOrphanedListings] = useState<Set<number>>(new Set());

    useEffect(() => {
        const handleFilter = () => {
            const textFilterRegEx = new RegExp(textFilter, 'img');
            setWmsOrphanedListingsFiltered(
                wmsOrphanedListings
                    .filter(
                        (listing) =>
                            textFilterRegEx.test(listing.title) ||
                            textFilterRegEx.test(listing.listingType) ||
                            textFilterRegEx.test(listing.id.toString())
                    )
                    .sort((a, b) => {
                        if (sortBy === 'id') {
                            return sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
                        } else if (sortBy === 'title') {
                            return sortOrder === 'asc'
                                ? a.title.localeCompare(b.title)
                                : b.title.localeCompare(a.title);
                        } else {
                            return 0;
                        }
                    })
            );
        };
        handleFilter();
    }, [wmsOrphanedListings, textFilter, sortBy, sortOrder]);

    const onClickRowWmsOrphans = {
        onClick: (_e, row: AttachedListingDTO) => {
            const siblings = wmsOrphanedListingsFiltered.map((l) => l.id).join(',');
            store.dispatch(push(`/profiles/${userId}/listings/${row.id}?siblings=${siblings}`));
        },
        onAuxClick: (_e, row: AttachedListingDTO) => {
            const siblings = wmsOrphanedListingsFiltered.map((l) => l.id).join(',');
            const win = window.open(`/profiles/${userId}/listings/${row.id}?siblings=${siblings}`, '_blank');
            if (!win) {
                win.focus();
            }
        },
    };

    const handleSelectAllWmsOrphans = (isSelected: boolean, rows: AttachedListingDTO[]) => {
        if (isSelected) {
            const allIds = rows.map((row) => row.id);
            setSelectedwmsOrphanedListings(new Set(allIds));
        } else {
            setSelectedwmsOrphanedListings(new Set());
        }
    };

    const handleSelectWmsOrphans = (row: AttachedListingDTO, isSelected: boolean) => {
        setSelectedwmsOrphanedListings((prevSelected) => {
            const newSelected = new Set(prevSelected);
            if (isSelected) {
                newSelected.add(row.id);
            } else {
                newSelected.delete(row.id);
            }
            return newSelected;
        });
    };

    if (!wmsOrphanedListings?.length) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            <ProfileListingsGenericTable
                subTitle={`Total of ${wmsOrphanedListings.length} orphaned WMS listings`}
                data={wmsOrphanedListingsFiltered}
                columns={COLS_LISTINGS}
                onClickRowEvent={onClickRowWmsOrphans}
                selectRow={{
                    mode: 'checkbox',
                    clickToSelect: true,
                    onSelect: handleSelectWmsOrphans,
                    onSelectAll: handleSelectAllWmsOrphans,
                }}
            />
            {selectedwmsOrphanedListings.size > 0 ? (
                <ProfileListingsWmsOrphanedUpdateActions
                    userId={userId}
                    selectedWmsOrphanedListings={selectedwmsOrphanedListings}
                />
            ) : null}
        </React.Fragment>
    );
};

export default ProfileListingsOrphanedTable;

const Checkmark = styled.i`
    color: green;
`;
