import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { AttachedWmsServerDTO } from '../../../../../api/api-managed-profiles';
import store from '../../../../../store/store';
import CopyBox from '../../../../Shared/copy-box';
import ProfileListingsGenericTable from './profile-listings-generic-table';

interface ProfileListingsWMSServersProps {
    wmsServers: AttachedWmsServerDTO[];
    textFilter: string;
    sortBy: string;
    sortOrder: string;
}

const COLS_WMS_SERVERS = [
    {
        dataField: 'id',
        text: 'ID',
        headerStyle: () => {
            return {
                width: '40px',
            };
        },
    },
    {
        dataField: 'standard',
        text: 'Standard',
    },
    {
        dataField: 'title',
        text: 'Title',
    },
    {
        dataField: 'url',
        text: 'URL',
        headerStyle: () => {
            return { textAlign: 'center' };
        },
        style: () => {
            return { textAlign: 'center', width: 'auto', maxWidth: '250px', overflow: 'hidden' };
        },
        formatter: (_cell, row: AttachedWmsServerDTO) => {
            return <CopyBox onCopyMessage="Server URL copied to clipboard!">{row.url}</CopyBox>;
        },
    },
    {
        dataField: 'layersCount',
        text: 'Total layers',
    },
    {
        dataField: 'listings',
        text: 'Available in Soar',
        formatter: (_, row: AttachedWmsServerDTO) => row.listings?.length,
    },
];

const ProfileListingsWMSServers = ({ wmsServers, textFilter, sortBy, sortOrder }: ProfileListingsWMSServersProps) => {
    const [wmsServersFiltered, setWmsServersFiltered] = useState<AttachedWmsServerDTO[]>(wmsServers ?? []);

    useEffect(() => {
        const textFilterRegEx = new RegExp(textFilter, 'img');
        setWmsServersFiltered(
            wmsServers
                .filter(
                    (wmsServer) =>
                        textFilterRegEx.test(wmsServer.title) ||
                        textFilterRegEx.test(wmsServer.url) ||
                        textFilterRegEx.test(wmsServer.standard) ||
                        textFilterRegEx.test(wmsServer.id.toString())
                )
                .sort((a, b) => {
                    if (sortBy === 'id') {
                        return sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
                    } else if (sortBy === 'title') {
                        return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
                    } else {
                        return 0;
                    }
                })
        );
    }, [wmsServers, textFilter, sortBy, sortOrder]);

    const onClickRowWMS = {
        onClick: (_e, row: AttachedWmsServerDTO) => {
            store.dispatch(push(`/wms/${row.id}`));
        },
        onAuxClick: (_e, row: AttachedWmsServerDTO) => {
            const win = window.open(`/wms/${row.id}`, '_blank');
            if (!win) {
                win.focus();
            }
        },
    };

    if (!wmsServers?.length) {
        return <React.Fragment />;
    }

    return (
        <ProfileListingsGenericTable
            subTitle={`Total of ${wmsServers.length} WMS/WMTS servers`}
            data={wmsServersFiltered}
            columns={COLS_WMS_SERVERS}
            onClickRowEvent={onClickRowWMS}
            divider
        />
    );
};

export default ProfileListingsWMSServers;
