import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiManagedProfiles, { ManagedProfileListingFullDTO } from '../../../../api/api-managed-profiles';
import { ListingDTO, Review } from '../../../../api/model';
import config from '../../../../config';
import { Button } from '../../../style';
import store from '../../../../store/store';
import { push } from 'connected-react-router';
import NotificationUtil from '../../../../lib/notification-util';
import { useLocation } from 'react-router';
import ConfirmModal from '../../../Shared/confirm-modal';

interface ProfileListingNavigationProps {
    userId: string;
    listing: ListingDTO;
    onListingUpdated: (updated: ManagedProfileListingFullDTO) => void;
}

const canListingBePublished = (listing: ListingDTO) => {
    return (
        listing.title.length > 0 &&
        listing.description.length > 0 &&
        listing.categories.length > 0 &&
        listing.previewUrl &&
        listing.bboxWKT.length > 0
    );
};

const ProfileListingNavigation = ({ userId, listing, onListingUpdated }: ProfileListingNavigationProps) => {
    const [loading, setLoading] = useState(false);
    const [siblings, setSiblings] = useState<number[]>([]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const siblingsParam = queryParams.get('siblings');

    useEffect(() => {
        if (siblingsParam) {
            const siblings = siblingsParam.split(',').map((s) => parseInt(s));
            if (siblings.includes(listing.id)) {
                setSiblings(siblings);
            }
        }
    }, [siblingsParam, listing.id]);

    const handleListingVisibility = async (visibility: boolean) => {
        if (!canListingBePublished(listing) && visibility) {
            alert('Ensure all required information are saved before making the layer publicly available');
            return;
        }
        setLoading(true);
        try {
            const res = await ApiManagedProfiles.updateListingVisibility(userId, listing.id, visibility);
            onListingUpdated(res);
        } catch (err) {
            NotificationUtil.showErrorNotificationApi(err);
        } finally {
            setLoading(false);
        }
    };

    const handleBackToProfile = () => {
        store.dispatch(push(`/profiles/${userId}`));
    };

    const handleNext = () => {
        const index = siblings.indexOf(listing.id);
        let nextListingId = siblings[0];
        if (index + 1 < siblings.length) {
            nextListingId = siblings[index + 1];
        }
        store.dispatch(push(`/profiles/${userId}/listings/${nextListingId}?siblings=${siblings.join(',')}`));
    };

    const handlePrevious = () => {
        const index = siblings.indexOf(listing.id);
        let previousListingId = siblings[siblings.length - 1];
        if (index - 1 >= 0) {
            previousListingId = siblings[index - 1];
        }
        store.dispatch(push(`/profiles/${userId}/listings/${previousListingId}?siblings=${siblings.join(',')}`));
    };

    const handleOpenInSoar = () => {
        window.open(`${config.CLIENT_URL}/maps/${listing.id}`);
    };

    const handleRefresh = async () => {
        setLoading(true);
        try {
            const res = await ApiManagedProfiles.getListing(userId, listing.id);
            onListingUpdated(res);
        } catch (err) {
            NotificationUtil.showErrorNotificationApi(err);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            await ApiManagedProfiles.deleteListing(userId, listing.id);
            store.dispatch(push(`/profiles/${userId}`));
        } catch (err) {
            NotificationUtil.showErrorNotificationApi(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            {listing.processing && (
                <React.Fragment>
                    <NotAvailableText>This listing is currently processing by the backend system</NotAvailableText>
                    <Button onClick={handleRefresh}>Refresh</Button>
                </React.Fragment>
            )}
            {!listing.processing && listing.review == Review.APPROVED && (
                <React.Fragment>
                    <AvailableText>This listing is currently available on Soar</AvailableText>
                    <Button disabled={loading} onClick={() => handleListingVisibility(false)}>
                        Turn it off
                    </Button>
                    <Button color="link" onClick={() => handleOpenInSoar()}>
                        Open in Soar
                    </Button>
                </React.Fragment>
            )}
            {!listing.processing && listing.review != Review.APPROVED && (
                <React.Fragment>
                    <NotAvailableText>This listing is not available on Soar</NotAvailableText>
                    <Button disabled={loading || listing.error} onClick={() => handleListingVisibility(true)}>
                        Turn it on
                    </Button>
                </React.Fragment>
            )}
            {siblings.length > 0 && (
                <React.Fragment>
                    <Button disabled={loading} onClick={handlePrevious}>
                        Previous
                    </Button>
                    <Button disabled={loading} onClick={handleNext}>
                        Next
                    </Button>
                </React.Fragment>
            )}

            <Button disabled={loading} onClick={handleBackToProfile}>
                Back to profile
            </Button>

            <ConfirmModal
                title={`Delete`}
                message={`Are you sure you want to delete listing? Action cannot be undone`}
                onConfirm={() => handleDelete()}
                color={'danger'}
            />
        </Container>
    );
};

export default ProfileListingNavigation;

const Container = styled.div`
    padding-top: 8px;
    display: inline-block;
`;

const Text = styled.span`
    font-weight: bolder;
    margin-right: 20px;
    font-size: 1.2rem;
    vertical-align: sub;
`;

const AvailableText = styled(Text)`
    color: #58a758;
`;

const NotAvailableText = styled(Text)`
    color: #fa4646;
`;
