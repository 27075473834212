import React, { useState } from 'react';
import { Button, FormGroup, Input, ConfirmModalFooter, ConfirmButtonContainer, Label } from '../../../../style';
import CategoriesInput from '../../../../Shared/categories-input-field';
import ConfirmModal from '../../../../Shared/confirm-modal';
import SoarModal from '../../../../Shared/soar-modal';
import TagInputField from '../../../../Shared/tag-input-field';
import ViewHelper from '../../../../view-helper';
import { push } from 'connected-react-router';
import ApiManagedProfiles, { ListingMetadata } from '../../../../../api/api-managed-profiles';
import NotificationUtil from '../../../../../lib/notification-util';
import store from '../../../../../store/store';
import { toast } from 'react-toastify';

interface ProfileListingsWmsOrphanedUpdateActionsProps {
    userId: string;
    selectedWmsOrphanedListings: Set<number>;
}

const ProfileListingsWmsOrphanedUpdateActions = ({
    userId,
    selectedWmsOrphanedListings,
}: ProfileListingsWmsOrphanedUpdateActionsProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState<string[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [license, setLicense] = useState<string>('');
    const [wmsModalOpen, setWmsModalOpen] = useState(false);
    const [wmsVisibility, setWmsVisibility] = useState(false);
    const [wmsModelVisibilityOpen, setWmsModelVisibilityOpen] = useState(false);

    const handleDeleteSelectedWmsOrphans = () => {
        setIsLoading(true);
        async function deleteSelected() {
            try {
                await ApiManagedProfiles.deleteBulkListings(userId, Array.from(selectedWmsOrphanedListings));
                store.dispatch(push(`/profiles/${userId}`));
                window.location.reload();
            } catch (err) {
                NotificationUtil.showErrorNotificationApi(err);
            } finally {
                setIsLoading(false);
            }
        }
        deleteSelected();
    };

    const handleValidateMetadata = () => {
        if (!categories?.length || !tags?.length || !license) {
            toast.error('No changes to apply');
            return false;
        }
        return true;
    };

    const handleUpdateWmsOrphansMetadata = () => {
        if (!handleValidateMetadata()) {
            return;
        }
        setIsLoading(true);
        async function updateWmsMetadata() {
            try {
                const metadata: ListingMetadata = {};
                if (categories?.length) metadata.categories = categories;
                if (tags?.length) metadata.tags = tags;
                if (license) metadata.license = license;

                await ApiManagedProfiles.updateListingMetadata(
                    userId,
                    Array.from(selectedWmsOrphanedListings),
                    metadata
                );
                setWmsModalOpen(false);
                store.dispatch(push(`/profiles/${userId}`));
                window.location.reload();
            } catch (err) {
                NotificationUtil.showErrorNotificationApi(err);
            } finally {
                setIsLoading(false);
            }
        }
        updateWmsMetadata();
    };

    const handleVisibilityUpdateWmsOrphans = () => {
        setIsLoading(true);
        async function updateWmsVisibility() {
            try {
                await ApiManagedProfiles.updateBulkListingVisibility(
                    userId,
                    Array.from(selectedWmsOrphanedListings),
                    wmsVisibility
                );
                setWmsModelVisibilityOpen(false);
                store.dispatch(push(`/profiles/${userId}`));
                window.location.reload();
            } catch (err) {
                NotificationUtil.showErrorNotificationApi(err);
            } finally {
                setIsLoading(false);
            }
        }
        updateWmsVisibility();
    };

    return (
        <div>
            <ConfirmModal
                title={`Delete`}
                message={`Are you sure you want to delete all ${selectedWmsOrphanedListings.size} selected listings? Action cannot be undone`}
                onConfirm={() => handleDeleteSelectedWmsOrphans()}
                color={'danger'}
            />
            <Button onClick={() => setWmsModalOpen(true)}>Update Metadata</Button>
            <Button onClick={() => setWmsModelVisibilityOpen(true)}>Update Visibility</Button>

            <SoarModal
                isOpen={wmsModalOpen}
                onClosed={() => setWmsModalOpen(false)}
                width="600px"
                title="Enter Metadata to update"
            >
                <FormGroup>
                    <Label for="categories" xs={12}>
                        Categories
                    </Label>
                    <CategoriesInput
                        values={categories}
                        onChange={(selectedCategories) => {
                            setCategories(selectedCategories);
                        }}
                        limit={3}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="tags" xs={12}>
                        Tags (to help with search)
                    </Label>
                    <TagInputField tags={tags} onTagInput={(tags) => setTags(tags)} />
                </FormGroup>
                <FormGroup>
                    <Label for="license" xs={12}>
                        License
                    </Label>
                    <Input
                        value={license}
                        onChange={(e) => {
                            setLicense(e.target.value);
                        }}
                        type="select"
                        name="license"
                    >
                        {<option value={''}>Choose license</option>}
                        {Object.values(ViewHelper.LISTINGS_LICENSES).map((value) => (
                            <option key={value} value={value}>
                                {value}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
                <ConfirmModalFooter margin="0px 15px 0px 0px">
                    <ConfirmButtonContainer>
                        <Button disabled={isLoading} onClick={handleUpdateWmsOrphansMetadata}>
                            {isLoading ? <i className="fa fa-spinner fa-spin" /> : 'Update'}
                        </Button>
                        <Button disabled={isLoading} color="warning" onClick={() => setWmsModalOpen(false)}>
                            Cancel
                        </Button>
                    </ConfirmButtonContainer>
                </ConfirmModalFooter>
            </SoarModal>
            <SoarModal
                isOpen={wmsModelVisibilityOpen}
                onClosed={() => setWmsModelVisibilityOpen(false)}
                width="600px"
                title="Update Visibility"
            >
                <FormGroup tag="fieldset">
                    <Label for="visibility" xs={12}>
                        Visibility
                    </Label>
                    <FormGroup check inline>
                        <Input
                            name="wmsVisibility"
                            type="radio"
                            required={true}
                            onChange={() => setWmsVisibility(true)}
                            checked={wmsVisibility === true}
                        />
                        <Label check onClick={() => setWmsVisibility(true)}>
                            Approved
                        </Label>
                    </FormGroup>
                    <FormGroup check inline style={{ marginLeft: '24px' }}>
                        <Input
                            name="wmsVisibility"
                            type="radio"
                            onChange={() => setWmsVisibility(false)}
                            checked={wmsVisibility === false}
                        />
                        <Label check onClick={() => setWmsVisibility(false)}>
                            Rejected
                        </Label>
                    </FormGroup>
                </FormGroup>
                <ConfirmModalFooter margin="0px 15px 0px 0px">
                    <ConfirmButtonContainer>
                        <Button disabled={isLoading} onClick={handleVisibilityUpdateWmsOrphans}>
                            {isLoading ? <i className="fa fa-spinner fa-spin" /> : 'Update'}
                        </Button>
                        <Button disabled={isLoading} color="warning" onClick={() => setWmsModelVisibilityOpen(false)}>
                            Cancel
                        </Button>
                    </ConfirmButtonContainer>
                </ConfirmModalFooter>
            </SoarModal>
        </div>
    );
};

export default ProfileListingsWmsOrphanedUpdateActions;
