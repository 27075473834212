import React from 'react';
import { Col, FormGroup, Input, Label, Row } from '../../../../style';
import TextInputWithClear from '../../../../Shared/text-input-with-clear';
import { SortBy } from './profile-listings';
import { SortOrder } from './profile-listings';

interface ProfileListingsFilteringProps {
    textFilter: string;
    setTextFilter: (text: string) => void;
    sortBy: SortBy;
    setSortBy: (sortBy: SortBy) => void;
    sortOrder: SortOrder;
    setSortOrder: (sortOrder: SortOrder) => void;
}

const ProfileListingsFiltering = ({
    textFilter,
    setTextFilter,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
}: ProfileListingsFilteringProps) => {
    return (
        <Row>
            <Col md={8}>
                <FormGroup>
                    <Label>Filter by ...</Label>
                    <TextInputWithClear value={textFilter} onChange={setTextFilter} placeholder="Search term ..." />
                </FormGroup>
            </Col>
            <Col md={2}>
                <FormGroup>
                    <Label>Sort by</Label>
                    <Input
                        value={sortBy}
                        onChange={(e) => {
                            setSortBy(e.target.value);
                        }}
                        type="select"
                        name="sortBy"
                    >
                        <option value={'id'}>ID</option>
                        <option value={'title'}>Title</option>
                    </Input>
                </FormGroup>
            </Col>
            <Col md={2}>
                <FormGroup>
                    <Label>Sort order</Label>
                    <Input
                        value={sortOrder}
                        onChange={(e) => {
                            setSortOrder(e.target.value);
                        }}
                        type="select"
                        name="sortOrder"
                    >
                        <option value={'asc'}>Ascending</option>
                        <option value={'desc'}>Descending</option>
                    </Input>
                </FormGroup>
            </Col>
        </Row>
    );
};

export default ProfileListingsFiltering;
