import React from 'react';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ApiVouchers from '../../api/api-vouchers';
import { Voucher, VoucherDTO } from '../../api/model';
import ErrorPanel from '../Shared/error-panel';
import SoarModal from '../Shared/soar-modal';
import { Button, Col, FormGroup, Input, Label, Row } from '../style';
import { handleErrorMessage } from '../Shared/error-message-handling';

interface Props {
    onVoucherCreated: (voucher: VoucherDTO) => void;
}

const CreateVoucherModal = (props: Props) => {
    const [opened, setOpened] = useState(false);
    const [code, setCode] = useState('');
    const [type, setType] = useState('CSW');
    const [amount, setAmount] = useState<number>(undefined);
    const [percentage, setPercentage] = useState<number>(undefined);
    const [validFrom, setValidFrom] = useState<Date>(undefined);
    const [validTo, setValidTo] = useState<Date>(undefined);
    const [usageLimit, setUsageLimit] = useState<number>(undefined);
    const [userMultiple, setUserMultiple] = useState(false);
    const [watermarkRequired, setWatermarkRequired] = useState(false);
    const [maxArea, setMaxArea] = useState<number>(undefined);
    const [maxImages, setMaxImages] = useState<number>(undefined);
    const [freeTrialInDays, setFreeTrialInDays] = useState<number>(undefined);
    const [durationInMonths, setDurationInMonths] = useState<number>(undefined);

    const [voucherError, setVoucherError] = useState<string>(undefined);

    const togglCreateVoucher = () => {
        setOpened(!opened);
    };

    const toggleClose = () => {
        setOpened(false);
        setVoucherError(undefined);
    };

    const createVoucher = async () => {
        try {
            const dto: Voucher = {
                code: code,
                type: type,
                amount: amount && amount > 0 ? amount * 100 : undefined,
                percentage: percentage && percentage > 0 ? percentage : undefined,
                validFrom: validFrom ? validFrom.getTime() / 1000 : undefined,
                validTo: validTo ? validTo.getTime() / 1000 : undefined,
                usageLimit: usageLimit,
                userMultiple: userMultiple,
            };
            if (type === 'CSW') {
                dto.maxArea = maxArea;
                dto.watermarkRequired = watermarkRequired;
            }
            if (type === 'DMT') {
                dto.maxImages = maxImages;
            }
            if (type === 'SOAR_PLUS') {
                dto.amount = undefined;
                dto.durationInMonth = durationInMonths;
                dto.freeTrialInDays = freeTrialInDays;
            }
            const result = await ApiVouchers.createVoucher(dto);
            props.onVoucherCreated(result);
            togglCreateVoucher();
        } catch (err) {
            const errorMessage = handleErrorMessage(err);
            setVoucherError(errorMessage);
        }
    };

    const amountChanged = (event) => {
        setAmount(event.target.value);
        setPercentage(0);
    };

    const percantageChanged = (event) => {
        let p = event.target.value;
        if (p > 100) p = 100;
        if (p < 0) p = 0;
        setPercentage(p);
        setAmount(0);
    };

    const onTypeChanged = (event) => {
        setType(event.target.value);
    };

    const onUsageLimitChanged = (event) => {
        setUsageLimit(event.target.value);
    };

    const onUserMultipleChanged = (event) => {
        if (!usageLimit || usageLimit <= 1) {
            toast.error('Please set the usage limit and try again');
            return;
        }
        setUserMultiple(event.target.checked);
    };

    const onWatermarkRequiredChanged = (event) => {
        setWatermarkRequired(event.target.checked);
    };

    const onMaxAreaChanged = (event) => {
        setMaxArea(event.target.value);
    };

    const onMaxImagesChanged = (event) => {
        setMaxImages(event.target.value);
    };

    const onFreeTrialInDaysChanged = (event) => {
        setFreeTrialInDays(event.target.value);
    };

    const onDurationInMonthsChanged = (event) => {
        setDurationInMonths(event.target.value);
    };
    return (
        <>
            <CreateVoucherButton>
                <Button onClick={togglCreateVoucher}>Create voucher</Button>
            </CreateVoucherButton>
            <SoarModal isOpen={opened} onClosed={toggleClose} title="Create A Voucher">
                <React.Fragment>
                    <Row>
                        <Col sm={12} md={6}>
                            <FormGroup>
                                <Label for="idCode">Type</Label>
                                <Input type="select" name="type" id="idType" value={type} onChange={onTypeChanged}>
                                    <option>CSW</option>
                                    <option>DMT</option>
                                    <option>SOAR_PLUS</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6}>
                            <FormGroup>
                                <Label for="idCode">Voucher Code (minimum 6 characters)</Label>
                                <Input value={code} onChange={(e) => setCode(e.target.value)} type="text" id="idCode" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            <FormGroup>
                                <Label for="idPercentage">Discount in %</Label>
                                <Input value={percentage} onChange={percantageChanged} type="number" id="idValue" />
                            </FormGroup>
                        </Col>
                        {type !== 'SOAR_PLUS' && (
                            <Col sm={12} md={6}>
                                <FormGroup>
                                    <Label for="idValue">Discount in USD</Label>
                                    <Input value={amount} onChange={amountChanged} type="number" id="idValue" />
                                </FormGroup>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            <FormGroup>
                                <Label for="idDateFrom">Valid From</Label>
                                <DatePicker
                                    id="idDateFrom"
                                    selected={validFrom}
                                    onChange={setValidFrom}
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                    autoComplete="none"
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6}>
                            <FormGroup>
                                <Label for="idDateTo">Valid To</Label>
                                <DatePicker
                                    id="idDateTo"
                                    selected={validTo}
                                    onChange={setValidTo}
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                    autoComplete="none"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6}>
                            <FormGroup>
                                <Label for="idUsageLimit">Usage limit</Label>
                                <Input
                                    value={usageLimit}
                                    onChange={onUsageLimitChanged}
                                    type="number"
                                    id="idUsageLimit"
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6}>
                            <CheckBoxFormGroup>
                                <Label for="idUserMultiple">Multiple Use</Label>
                                <Input
                                    type="switch"
                                    id="idUserMultiple"
                                    checked={userMultiple}
                                    onChange={onUserMultipleChanged}
                                />
                            </CheckBoxFormGroup>
                        </Col>
                    </Row>
                    {type === 'CSW' && (
                        <Row>
                            <Col sm={12} md={6}>
                                <FormGroup>
                                    <Label for="iMaxArea">Max area</Label>
                                    <Input value={maxArea} onChange={onMaxAreaChanged} type="number" id="idMaxArea" />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={6}>
                                <CheckBoxFormGroup>
                                    <Label for="idWatermarkRequired">Watermark required</Label>
                                    <Input
                                        type="switch"
                                        id="idWatermarkRequired"
                                        checked={watermarkRequired}
                                        onChange={onWatermarkRequiredChanged}
                                    />
                                </CheckBoxFormGroup>
                            </Col>
                        </Row>
                    )}
                    {type === 'DMT' && (
                        <Row>
                            <Col sm={12} md={6}>
                                <FormGroup>
                                    <Label for="iMaxImages">Max images</Label>
                                    <Input
                                        value={maxImages}
                                        onChange={onMaxImagesChanged}
                                        type="number"
                                        id="idMaxImages"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    )}
                    {type === 'SOAR_PLUS' && (
                        <Row>
                            <Col sm={12} md={6}>
                                <FormGroup>
                                    <Label for="idFreeTrialInDays">Free trial in days</Label>
                                    <Input
                                        value={freeTrialInDays}
                                        onChange={onFreeTrialInDaysChanged}
                                        type="number"
                                        id="idFreeTrialInDays"
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={6}>
                                <FormGroup>
                                    <Label for="idDurationInMonths">Discount duration in month</Label>
                                    <Input
                                        value={durationInMonths}
                                        onChange={onDurationInMonthsChanged}
                                        type="number"
                                        id="idDurationInMonths"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    )}

                    {voucherError && <ErrorPanel>{voucherError}</ErrorPanel>}
                </React.Fragment>
                <ModalFooter>
                    <ButtonContainer>
                        <Button onClick={createVoucher}>Create</Button>
                        <Button color="warning" onClick={toggleClose}>
                            Cancel
                        </Button>
                    </ButtonContainer>
                </ModalFooter>
            </SoarModal>
        </>
    );
};

export default CreateVoucherModal;

const ModalFooter = styled.div`
    margin: 20px 15px 0px 0px;
    float: right;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 175px;
    justify-content: space-between;
`;

const CheckBoxFormGroup = styled(FormGroup)`
    margin-top: 38px;
    .form-check-input {
        margin-left: 20px;
        width: 20px;
        height: 20px;
    }
`;

const CreateVoucherButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin: 5px 0px 15px 0px;

    .btn-secondary {
        margin-right: 0px;
    }
`;
