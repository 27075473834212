import {
    AnalyticsFilter,
    AnalyticsReportDTO,
    DailyReportCFDTO,
    DailyReportCFFilter,
    DrawProjectReportDTO,
    HeatmapResult,
    MainReportDTO,
    MonthlyReportCFDTO,
    SoarReportDTO,
    SubdomainsReportDTO,
} from '../../api/model';
import { Action } from '../root-reducer';
import { actionTypes as at } from './constants';

export interface IReduxReportsStore {
    totalMapInteractions?: number;
    mainReport?: MainReportDTO;
    mainReportError?: string;

    soarReport?: SoarReportDTO;
    soarReportError?: string;

    subdomainsReport?: SubdomainsReportDTO;
    subdomainsReportError?: string;

    analyticsHeatmapFilter?: AnalyticsFilter;
    analyticsHeatmap?: HeatmapResult[];
    analyticsHeatmapError?: string;

    analyticsMonthlyReports?: AnalyticsReportDTO[];
    analyticsMonthlyReportsError?: string;

    cloudflareMonthlyReport?: MonthlyReportCFDTO[];
    cloudflareMonthlyReportError?: string;

    cloudflareDailyFilter?: DailyReportCFFilter;
    cloudflareDailyReport?: DailyReportCFDTO[];
    cloudflareDailyReportError?: string;
}

const initialState: IReduxReportsStore = {};

export default function (state: IReduxReportsStore = initialState, action: Action): IReduxReportsStore {
    switch (action.type) {
        case at.REPORTS_MAIN_REPORT_UPDATED:
            return { ...state, mainReport: action.payload, mainReportError: undefined };
        case at.REPORTS_MAIN_REPORT_ERROR:
            return { ...state, mainReportError: action.payload, mainReport: undefined };
        case at.REPORTS_SOAR_REPORT_UPDATED:
            return { ...state, soarReport: action.payload, soarReportError: undefined };
        case at.REPORTS_SOAR_REPORT_ERROR:
            return { ...state, soarReportError: action.payload, soarReport: undefined };
        case at.REPORTS_SUBDOMAINS_REPORT_UPDATED:
            return { ...state, subdomainsReport: action.payload, subdomainsReportError: undefined };
        case at.REPORTS_SUBDOMAINS_REPORT_ERROR:
            return { ...state, subdomainsReportError: action.payload, subdomainsReport: undefined };

        case at.REPORTS_ANALYTICS_HEATMAP:
            return {
                ...state,
                analyticsHeatmap: action.payload.data,
                analyticsHeatmapFilter: action.payload.filter,
                analyticsHeatmapError: undefined,
            };
        case at.REPORTS_ANALYTICS_HEATMAP_ERROR:
            return { ...state, analyticsHeatmapError: action.payload, analyticsHeatmap: undefined };

        case at.REPORTS_ANALYTICS_MONTHLY_REPORT:
            return { ...state, analyticsMonthlyReports: action.payload, analyticsMonthlyReportsError: undefined };
        case at.REPORTS_ANALYTICS_MONTHLY_REPORT_ERROR:
            return { ...state, analyticsMonthlyReportsError: action.payload, analyticsMonthlyReports: undefined };

        case at.REPORTS_CLOUDFLARE_DAILY_REPORT:
            return {
                ...state,
                cloudflareDailyReport: action.payload.data,
                cloudflareDailyFilter: action.payload.filter,
                cloudflareDailyReportError: undefined,
            };
        case at.REPORTS_CLOUDFLARE_DAILY_REPORT_ERROR:
            return { ...state, cloudflareDailyReportError: action.payload, cloudflareDailyReport: undefined };

        case at.REPORTS_CLOUDFLARE_MONTHLY_REPORT:
            return { ...state, cloudflareMonthlyReport: action.payload, cloudflareMonthlyReportError: undefined };
        case at.REPORTS_CLOUDFLARE_MONTHLY_REPORT_ERROR:
            return { ...state, cloudflareMonthlyReportError: action.payload, cloudflareMonthlyReport: undefined };
        case at.TOTAL_MAP_INTERACTIONS_UPDATED:
            return { ...state, totalMapInteractions: action.payload };
        default:
            return state;
    }
}
