import L from 'leaflet';
import Api from './api';
import { UploadCredentialsDTO } from './model';

export interface WMSLayer {
    id: number;
    name: string;
    serverId: number;
    standard: OGCStandard;
    url: string;
    excluded: boolean;
    boundingBoxWKT: string;
    boundingBoxWKTOvr: string;
    title: string;
    titleOvr: string;
    abstract: string;
    abstractOvr: string;
    keywords: string[];
    keywordsOvr: string[];
    legendUrls: string[];
    legendUrl: string;
    legendUrlCustom?: string;
    listingId: number;
    categories: string[];
    srs: string;
    srsOvr?: string;
    srsOptions?: string[];
    note: string;
    public: boolean;
    previewUrl: string;
    imageFormat: string;
    adminNotes?: string;
    wmtsType?: WMTSType;
    style?: string;
    tileMatrixSet?: TileMatrixSet;
    resourceUrl?: string;
    version?: string;
}

export interface WMSServer {
    id: number;
    userId: string;
    url: string;
    standard: OGCStandard;
    avatarUrl: string;
    excluded: true;
    version: string;
    speedKbps: number;
    publicLayers: number;
    layers: number;
    domainName: string;
    createdBy: string;
    created: string;
    updated: string;
    title: string;
    titleOvr: string;
    abstract: string;
    abstractOvr: string;
    keywords: string[];
    keywordsOvr: string[];
    license: string;
    adminNotes?: string;
    error?: string;
    skipProxy?: boolean;
}

export enum OGCStandard {
    WMS = 'WMS',
    WMTS = 'WMTS',
}

export enum WMTSType {
    KVP = 'KVP',
    REST = 'REST',
}

export const CRSDefs = {
    'EPSG:3395': L.CRS.EPSG3395,
    'EPSG:3857': L.CRS.EPSG3857,
    'EPSG:4326': L.CRS.EPSG4326,
};

export interface TileMatrixSet {
    identifier: string;
    crs: string;
    matrices: TileMatrix;
}

export interface TileMatrix {
    identifier: string;
    topLeftPoint: string;
    tileWidth: number;
    tileHeight: number;
    matrixWidth: number;
    matrixHeight: number;
}

export interface BodyUpdateWMSLayer {
    title?: string;
    abstract?: string;
    keywords?: string[];
    categories?: string[];
    boundingBoxWKT?: string;
    srs?: string;
    legendUrl?: string;
    adminNotes?: string;
}

export default class ApiWms extends Api {
    public static getWMS(): Promise<WMSServer[]> {
        return this.axios.get(`/v1/admin/wms`).then((res) => res.data.list);
    }

    public static getWMSServer(id: number): Promise<WMSServer> {
        return this.axios.get(`/v1/admin/wms/${id}`).then((res) => res.data);
    }

    public static getWMSLayers(id: number): Promise<WMSLayer[]> {
        return this.axios.get(`/v1/admin/wms/${id}/layers`).then((res) => res.data.list);
    }

    public static getWMSLayer(serverId: number, layerId: number): Promise<WMSLayer> {
        return this.axios.get(`/v1/admin/wms/${serverId}/layers/${layerId}`).then((res) => res.data as WMSLayer);
    }

    public static createWMSServer(url: string, standard: OGCStandard, userId?: string): Promise<WMSServer> {
        return this.axios.post('/v1/admin/wms', { url, standard, userId }).then((res) => {
            return res.data;
        });
    }

    public static deleteWMSServer(id: number): Promise<boolean> {
        return this.axios.delete(`/v1/admin/wms/${id}`).then((res) => {
            return res.data;
        });
    }

    public static refreshWMSServer(id: number): Promise<WMSServer> {
        return this.axios.get(`/v1/admin/wms/${id}/refresh`).then((res) => {
            return res.data;
        });
    }

    public static updateSkipProxy(serverId: number, proxy: boolean) {
        return this.axios.put(`/v1/admin/wms/${serverId}/proxy_skip`, { proxy });
    }

    public static linkWmsServer(sourceSserverId: number, targetServerId: number): Promise<WMSServer> {
        return this.axios
            .put(`/v1/admin/wms/${sourceSserverId}/link`, { serverId: targetServerId })
            .then((res) => res.data);
    }

    public static updateWMSServerDetails(
        id: number,
        wmsTitle: string,
        license?: string,
        adminNotes?: string
    ): Promise<WMSServer> {
        const body = {
            title: wmsTitle,
            license,
            adminNotes,
        };

        return this.axios.put(`/v1/admin/wms/${id}`, body).then((res) => {
            return res.data;
        });
    }

    public static updateWMSLayerDetails(serverId: number, layerId: number, body: BodyUpdateWMSLayer): Promise<boolean> {
        return this.axios.put(`v1/admin/wms/${serverId}/layers/${layerId}`, body).then((res) => {
            return res.data;
        });
    }

    public static attachExistingMap(serverId: number, layerId: number, listingId: number): Promise<WMSLayer> {
        return this.axios.put(`v1/admin/wms/${serverId}/layers/${layerId}/attach-map`, { listingId }).then((res) => {
            return res.data;
        });
    }

    public static updateWMSLayerVisibility(serverId: number, layerId: number, isVisible: boolean): Promise<boolean> {
        const body = {
            public: isVisible,
        };
        return this.axios.put(`/v1/admin/wms/${serverId}/layers/${layerId}/visibility`, body).then((res) => {
            return res.data;
        });
    }

    public static getWMSServerAvatarUploadCredentials(serverId: number): Promise<UploadCredentialsDTO> {
        return this.axios.get(`/v1/admin/wms/${serverId}/avatar`).then((res) => {
            return res.data;
        });
    }

    public static getWMSLayerPreviewUploadCredentials(
        serverId: number,
        layerId: number
    ): Promise<UploadCredentialsDTO> {
        return this.axios.get(`v1/admin/wms/${serverId}/layers/${layerId}/preview`).then((res) => {
            return res.data;
        });
    }

    public static addWMSLayerLegend(serverId, layerId, legendCustomUrl): Promise<boolean> {
        const params = {
            legendUrl: legendCustomUrl,
        };

        return this.axios.put(`v1/admin/wms/${serverId}/layers/${layerId}`, params).then((res) => {
            return res.data;
        });
    }

    public static getWMSLegendUploadCredentials(serverId: number, layerId: number): Promise<UploadCredentialsDTO> {
        return this.axios.get(`v1/admin/wms/${serverId}/layers/${layerId}/legend`).then((res) => {
            return res.data;
        });
    }

    public static deleteWMSLayerLegend(serverId: number, layerId: number): Promise<boolean> {
        const params = {
            legendUrl: '',
        };
        return this.axios
            .put(`v1/admin/wms/${serverId}/layers/${layerId}`, params)
            .then((res) => {
                return res.data;
            })
            .catch((err) => console.log(err));
    }
}
