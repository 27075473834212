import React, { useState } from 'react';
import { ManagedProfileFullDTO } from '../../../../../api/api-managed-profiles';
import ProfileListingsFiltering from './profile-listings-filtering';
import ProfileListingsTable from './profile-listings-table';
import ProfileListingsOrphanedTable from './profile-listings-wms-orphaned-table';
import ProfileListingsWMSServers from './profile-listings-wms-servers';
import ProfileListingsStacServers from './profile-listings-stac-catalogs';

interface TMSServerLayersProps {
    profile: ManagedProfileFullDTO;
}

export type SortBy = 'title' | 'id';
export type SortOrder = 'asc' | 'desc';

const ProfileListings = ({ profile }: TMSServerLayersProps) => {
    const { user, listings, wmsServers, wmsOrphanedListings, stacCatalogs } = profile;
    const { userId } = user;
    const [textFilter, setTextFilter] = useState<string>('');
    const [sortBy, setSortBy] = useState<SortBy>('id');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

    return (
        <React.Fragment>
            <ProfileListingsFiltering
                textFilter={textFilter}
                setTextFilter={setTextFilter}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
            />

            <ProfileListingsWMSServers
                wmsServers={wmsServers}
                textFilter={textFilter}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />

            <ProfileListingsStacServers
                stacCatalogs={stacCatalogs}
                textFilter={textFilter}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />

            <ProfileListingsTable
                userId={userId}
                listings={listings}
                sortBy={sortBy}
                sortOrder={sortOrder}
                textFilter={textFilter}
            />

            <ProfileListingsOrphanedTable
                userId={userId}
                wmsOrphanedListings={wmsOrphanedListings}
                sortBy={sortBy}
                sortOrder={sortOrder}
                textFilter={textFilter}
            />
        </React.Fragment>
    );
};

export default ProfileListings;
