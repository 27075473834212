import React from 'react';
import { Multiselect } from '../style';
import { toast } from 'react-toastify';

export interface PrimaryCategory {
    value: string;
    label: string;
    count?: number;
}

export const filterPrimaryCategories = (categories: string[]): string[] => {
    return categories.filter((category) => categoriesOptions.findIndex((pc) => pc.value == category) >= 0);
};

export const categoriesOptions: PrimaryCategory[] = [
    { value: 'agriculture', label: 'Agriculture' },
    { value: 'climate', label: 'Climate' },
    { value: 'earth-art', label: 'Earth Art' },
    { value: 'economic', label: 'Economic' },
    { value: 'environment', label: 'Environment' },
    { value: 'geology', label: 'Geology' },
    { value: 'historical', label: 'Historical' },
    { value: 'imagery', label: 'Imagery' },
    { value: 'landcover', label: 'Landcover' },
    { value: 'nautical', label: 'Nautical' },
    { value: 'political', label: 'Political' },
    { value: 'radar', label: 'Radar' },
    { value: 'terrain', label: 'Terrain' },
    { value: 'topographical', label: 'Topographical' },
    { value: 'transport', label: 'Transport ' },
    { value: 'urban', label: 'Urban' },
];

interface Props {
    values?: string[];
    onChange: (tags: string[]) => void;
    disabled?: boolean;
    limit?: number;
}

const mapExisting = (values?: string[]): PrimaryCategory[] => {
    return values?.map((category) => {
        return categoriesOptions.find((c) => c.value === category?.toLowerCase());
    });
};

const mapIntoCategories = (primaryCategories?: PrimaryCategory[]): string[] => {
    return primaryCategories?.map((pc) => pc.value) || [];
};

const CategoriesInput = ({ values, onChange, disabled, limit }: Props) => {
    return (
        <Multiselect
            isMulti
            disabled={disabled}
            closeMenuOnSelect={false}
            name={'categories'}
            options={categoriesOptions}
            value={mapExisting(values)}
            onChange={(selectedCategories: PrimaryCategory[] | undefined) => {
                if (!selectedCategories) return;

                const newCategories = mapIntoCategories(selectedCategories);

                if (limit && selectedCategories.length > limit) {
                    toast.error(`You can only select up to ${limit} categories`);
                    return;
                }

                onChange(newCategories);
            }}
            styles={{
                menu: (styles) => ({
                    ...styles,
                    zIndex: 99999,
                }),
            }}
        />
    );
};

export default CategoriesInput;
