import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ApiListings from '../../api/api-listings';
import { ListingDTO, ListingType } from '../../api/model';
import { selectFeatured } from '../../store/AppStore/selectors';
import ErrorPanel from '../Shared/error-panel';
import { Button, Card, Container, Row, Spinner, Title } from '../style';
import Filter from './filter';
import SearchCard from './search-card';
import styled from 'styled-components';
import SearchTableView from './search-table-view';
import Masonry from 'react-masonry-css';
import { handleErrorMessage } from '../Shared/error-message-handling';

const LIMIT_INCREMENT = 10;
const TABLE_VIEW_LIMIT = 1000;

interface Props {
    isAddFeatured?: boolean;
    featuredName?: string;
    featuredType?: string;
    featuredCount?: number;
}

export interface SearchParams {
    listingId?: number;
    keywords?: string;
    email?: string;
    category?: string;
    status?: string;
    listingType?: ListingType;
    from?: number;
    to?: number;
}

const Search = (props: Props) => {
    const [fetching, setFetching] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [listings, setListings] = useState<ListingDTO[]>(undefined);
    const [error, setError] = useState<string>(undefined);
    const [params, setParams] = useState<SearchParams>(undefined);
    const [tableView, setTableView] = useState<boolean>();

    const featured = useSelector(selectFeatured);

    const fetchListings = async (params: SearchParams) => {
        setFetching(true);
        setError(undefined);
        setParams(params);
        try {
            if (params.listingId) {
                await fetchSingleListing(params.listingId);
            } else {
                await searchListings(params);
            }
        } finally {
            setFetching(false);
        }
    };

    const fetchSingleListing = async (listingId: number) => {
        try {
            const listing = await ApiListings.getListing(listingId);
            setListings([listing]);
        } catch (err) {
            const errorMessage = handleErrorMessage(err);
            setError(errorMessage);
        }
    };

    const searchListings = async (params: SearchParams) => {
        try {
            const resultLimit = tableView ? TABLE_VIEW_LIMIT : LIMIT_INCREMENT;
            const listings = await ApiListings.listingsSearch(
                params.category,
                params.keywords,
                params.email,
                params.status,
                params.listingType,
                params.from,
                params.to,
                0,
                resultLimit
            );
            setListings(listings);
        } catch (err) {
            const errorMessage = handleErrorMessage(err);
            setError(errorMessage);
        }
    };

    const loadMore = async () => {
        setLoadingMore(true);
        try {
            const offset = listings.length;
            const resultLimit = tableView ? TABLE_VIEW_LIMIT : LIMIT_INCREMENT;
            const fetchedListings = await ApiListings.listingsSearch(
                params.category,
                params.keywords,
                params.email,
                params.status,
                params.listingType,
                params.from,
                params.to,
                offset,
                resultLimit
            );
            setListings(listings.concat(fetchedListings));
        } catch (err) {
            const errorMessage = handleErrorMessage(err);
            setError(errorMessage);
        } finally {
            setLoadingMore(false);
        }
    };

    const deleteListing = async (listingId: number) => {
        try {
            await ApiListings.deleteListing(listingId);
            const filtered = listings.filter((l) => l.id !== listingId);
            setListings(filtered);
        } catch (err) {
            const errorMessage = handleErrorMessage(err);
            setError(errorMessage);
        }
    };

    const showLoadMore = (): boolean => {
        if (listings) {
            const modulo = listings.length % (tableView ? TABLE_VIEW_LIMIT : LIMIT_INCREMENT);
            const r = listings.length !== 0 && modulo === 0 && !loadingMore;
            return r;
        }
        return false;
    };

    const breakpointColumnsObj = {
        default: 2,
        1300: 1,
    };

    let content;
    if (fetching) {
        content = (
            <LoadingCard>
                <Spinner text={'Fetching listing from server'} />
            </LoadingCard>
        );
    } else if (error) {
        content = <ErrorPanel>{error}</ErrorPanel>;
    } else if (listings && listings.length > 0) {
        content = (
            <React.Fragment>
                {tableView ? (
                    <SearchTableView listings={listings} />
                ) : (
                    <React.Fragment>
                        <MasonryComponent breakpointCols={breakpointColumnsObj} columnClassName="masonry-grid_column">
                            {listings.map((listing) => {
                                return (
                                    <SearchCard
                                        listing={listing}
                                        featured={featured}
                                        deleteListing={deleteListing}
                                        isAddFeatured={props.isAddFeatured}
                                        featuredName={props.featuredName}
                                        featuredType={props.featuredType}
                                        featuredCount={props.featuredCount}
                                    />
                                );
                            })}
                        </MasonryComponent>
                        {showLoadMore() && (
                            <LoadMoreButton color="link" onClick={loadMore}>
                                Load more
                            </LoadMoreButton>
                        )}
                        {loadingMore && (
                            <LoadingCard>
                                <Spinner text={'Loading more results'} />
                            </LoadingCard>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    } else if (listings) {
        content = <div>No listings found with requested filter</div>;
    } else {
        content = <div>Use filter and press SEARCH button to get results</div>;
    }
    return (
        <Container>
            <Row>
                {!props.isAddFeatured ? <Title>Manage Maps</Title> : null}
                <Filter
                    onChange={fetchListings}
                    handleTableView={() => setTableView(!tableView)}
                    tableView={tableView}
                />
            </Row>
            <Row>{content}</Row>
        </Container>
    );
};

export default Search;

const LoadMoreButton = styled(Button)`
    margin-left: 11px;
`;

const LoadingCard = styled(Card)`
    height: 300px;
`;

const MasonryComponent = styled(Masonry)`
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: 100%;

    .masonry-grid_column {
        margin-bottom: 10px; /* gutter size */
    }
`;
