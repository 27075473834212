import React, { useState } from 'react';
import { Button, FormGroup, Input, ConfirmModalFooter, ConfirmButtonContainer, Label } from '../../../../style';
import CategoriesInput from '../../../../Shared/categories-input-field';
import ConfirmModal from '../../../../Shared/confirm-modal';
import SoarModal from '../../../../Shared/soar-modal';
import TagInputField from '../../../../Shared/tag-input-field';
import ViewHelper from '../../../../view-helper';
import { push } from 'connected-react-router';
import ApiManagedProfiles, { ListingMetadata } from '../../../../../api/api-managed-profiles';
import NotificationUtil from '../../../../../lib/notification-util';
import store from '../../../../../store/store';
import { toast } from 'react-toastify';

interface ProfileListingsBulkUpdateActionsProps {
    userId: string;
    selectedListings: Set<number>;
}

const ProfileListingsBulkUpdateActions = ({ userId, selectedListings }: ProfileListingsBulkUpdateActionsProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalVisibilityOpen, setModalVisibilityOpen] = useState(false);
    const [visibility, setVisibility] = useState(false);
    const [categories, setCategories] = useState<string[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [license, setLicense] = useState<string>('');

    const handleDeleteSelected = () => {
        setIsLoading(true);
        async function deleteSelected() {
            try {
                await ApiManagedProfiles.deleteBulkListings(userId, Array.from(selectedListings));
                store.dispatch(push(`/profiles/${userId}`));
                window.location.reload();
            } catch (err) {
                NotificationUtil.showErrorNotificationApi(err);
            } finally {
                setIsLoading(false);
            }
        }
        deleteSelected();
    };

    const handleValidateMetadata = () => {
        if (!categories?.length && !tags?.length && !license) {
            toast.error('No changes to apply');
            return false;
        }
        return true;
    };

    const handleUpdateMetadata = () => {
        if (!handleValidateMetadata()) {
            return;
        }

        setIsLoading(true);

        async function updateMetadata() {
            try {
                const metadata: ListingMetadata = {};
                if (categories?.length) metadata.categories = categories;
                if (tags?.length) metadata.tags = tags;
                if (license) metadata.license = license;

                await ApiManagedProfiles.updateListingMetadata(userId, Array.from(selectedListings), metadata);

                setModalOpen(false);
                store.dispatch(push(`/profiles/${userId}`));
                window.location.reload();
            } catch (err) {
                NotificationUtil.showErrorNotificationApi(err);
            } finally {
                setIsLoading(false);
            }
        }

        updateMetadata();
    };

    const handleVisibilityUpdate = () => {
        setIsLoading(true);
        async function updateVisibility() {
            try {
                await ApiManagedProfiles.updateBulkListingVisibility(userId, Array.from(selectedListings), visibility);
                setModalVisibilityOpen(false);
                store.dispatch(push(`/profiles/${userId}`));
                window.location.reload();
            } catch (err) {
                NotificationUtil.showErrorNotificationApi(err);
            } finally {
                setIsLoading(false);
            }
        }
        updateVisibility();
    };

    return (
        <div>
            <ConfirmModal
                title={`Delete`}
                message={`Are you sure you want to delete all ${selectedListings.size} selected listings? Action cannot be undone`}
                onConfirm={() => handleDeleteSelected()}
                color={'danger'}
            />
            <Button onClick={() => setModalOpen(true)}>Update Metadata</Button>
            <Button onClick={() => setModalVisibilityOpen(true)}>Update Visibility</Button>

            <SoarModal
                isOpen={modalOpen}
                onClosed={() => setModalOpen(false)}
                width="600px"
                title="Enter Metadata to update"
            >
                <FormGroup>
                    <Label for="categories" xs={12}>
                        Categories
                    </Label>
                    <CategoriesInput
                        values={categories}
                        onChange={(selectedCategories) => {
                            setCategories(selectedCategories);
                        }}
                        limit={3}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="tags" xs={12}>
                        Tags (to help with search)
                    </Label>
                    <TagInputField tags={tags} onTagInput={(tags) => setTags(tags)} />
                </FormGroup>

                <FormGroup>
                    <Label for="license" xs={12}>
                        License
                    </Label>
                    <Input
                        value={license}
                        onChange={(e) => {
                            setLicense(e.target.value);
                        }}
                        type="select"
                        name="license"
                    >
                        {<option value={''}>Choose license</option>}
                        {Object.values(ViewHelper.LISTINGS_LICENSES).map((value) => (
                            <option key={value} value={value}>
                                {value}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
                <ConfirmModalFooter margin="0px 15px 0px 0px">
                    <ConfirmButtonContainer>
                        <Button disabled={isLoading} onClick={handleUpdateMetadata}>
                            {isLoading ? <i className="fa fa-spinner fa-spin" /> : 'Update'}
                        </Button>
                        <Button disabled={isLoading} color="warning" onClick={() => setModalOpen(false)}>
                            Cancel
                        </Button>
                    </ConfirmButtonContainer>
                </ConfirmModalFooter>
            </SoarModal>

            <SoarModal
                isOpen={modalVisibilityOpen}
                onClosed={() => setModalVisibilityOpen(false)}
                width="600px"
                title="Update Visibility"
            >
                <FormGroup tag="fieldset">
                    <Label for="visibility" xs={12}>
                        Visibility
                    </Label>
                    <FormGroup check inline>
                        <Input
                            name="visibility"
                            type="radio"
                            required={true}
                            onChange={() => setVisibility(true)}
                            checked={visibility === true}
                        />
                        <Label check onClick={() => setVisibility(true)}>
                            Approved
                        </Label>
                    </FormGroup>
                    <FormGroup check inline style={{ marginLeft: '24px' }}>
                        <Input
                            name="visibility"
                            type="radio"
                            onChange={() => setVisibility(false)}
                            checked={visibility === false}
                        />
                        <Label check onClick={() => setVisibility(false)}>
                            Rejected
                        </Label>
                    </FormGroup>
                </FormGroup>

                <ConfirmModalFooter margin="0px 15px 0px 0px">
                    <ConfirmButtonContainer>
                        <Button disabled={isLoading} onClick={handleVisibilityUpdate}>
                            {isLoading ? <i className="fa fa-spinner fa-spin" /> : 'Update'}
                        </Button>
                        <Button disabled={isLoading} color="warning" onClick={() => setModalVisibilityOpen(false)}>
                            Cancel
                        </Button>
                    </ConfirmButtonContainer>
                </ConfirmModalFooter>
            </SoarModal>
        </div>
    );
};

export default ProfileListingsBulkUpdateActions;
