/* Action types */
export const actionTypes = {
    TOTAL_MAP_INTERACTIONS_UPDATED: 'REPORTS/TOTAL_MAP_INTERACTIONS_UPDATED',

    REPORTS_MAIN_REPORT_UPDATED: 'REPORTS/MAIN_REPORT_UPDATED',
    REPORTS_MAIN_REPORT_ERROR: 'REPORTS/MAIN_REPORT_ERROR',

    REPORTS_SOAR_REPORT_UPDATED: 'REPORTS/SOAR_REPORT_UPDATED',
    REPORTS_SOAR_REPORT_ERROR: 'REPORTS/SOAR_REPORT_ERROR',

    REPORTS_SUBDOMAINS_REPORT_UPDATED: 'REPORTS/SUBDOMAINS_REPORT_UPDATED',
    REPORTS_SUBDOMAINS_REPORT_ERROR: 'REPORTS/SUBDOMAINS_REPORT_ERROR',

    REPORTS_ANALYTICS_HEATMAP: 'REPORTS/ANALYTICS_HEATMAP',
    REPORTS_ANALYTICS_HEATMAP_ERROR: 'REPORTS/ANALYTICS_HEATMAP_ERROR',

    REPORTS_ANALYTICS_MONTHLY_REPORT: 'REPORTS/ANALYTICS_MONTHLY_REPORT',
    REPORTS_ANALYTICS_MONTHLY_REPORT_ERROR: 'REPORTS/ANALYTICS_MONTHLY_REPORT_ERROR',

    REPORTS_CLOUDFLARE_MONTHLY_REPORT: 'REPORTS/CLOUDFLARE_MONTHLY_REPORT',
    REPORTS_CLOUDFLARE_MONTHLY_REPORT_ERROR: 'REPORTS/CLOUDFLARE_MONTHLY_REPORT_ERROR',

    REPORTS_CLOUDFLARE_DAILY_REPORT: 'REPORTS/CLOUDFLARE_DAILY_REPORT',
    REPORTS_CLOUDFLARE_DAILY_REPORT_ERROR: 'REPORTS/CLOUDFLARE_DAILY_REPORT_ERROR',
};
