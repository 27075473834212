import React, { useEffect, useState } from 'react';
import { AttachedSTACCatalogDTO, AttachedWmsServerDTO } from '../../../../../api/api-managed-profiles';
import store from '../../../../../store/store';
import { push } from 'connected-react-router';
import ProfileListingsGenericTable from './profile-listings-generic-table';

interface ProfileListingsStacCatalogsProps {
    stacCatalogs: AttachedSTACCatalogDTO[];
    textFilter: string;
    sortBy: string;
    sortOrder: string;
}

const COLS_STAC_CATALOGS = [
    {
        dataField: 'id',
        text: 'ID',
        headerStyle: () => {
            return {
                width: '40px',
            };
        },
    },
    {
        dataField: 'standard',
        text: 'Standard',
    },
    {
        dataField: 'title',
        text: 'Title',
    },
    {
        dataField: 'catalogsCount',
        text: 'Total sub-catalogs',
    },
    {
        dataField: 'itemsCount',
        text: 'Total items',
    },
    {
        dataField: 'listings',
        text: 'Available in Soar',
        formatter: (_, row: AttachedSTACCatalogDTO) => row.listings?.length,
    },
];

const ProfileListingsStacCatalogs = ({
    stacCatalogs,
    textFilter,
    sortBy,
    sortOrder,
}: ProfileListingsStacCatalogsProps) => {
    const [stacCatalogsFiltered, setStacCatalogsFiltered] = useState<AttachedSTACCatalogDTO[]>(stacCatalogs);

    useEffect(() => {
        const textFilterRegEx = new RegExp(textFilter, 'img');
        setStacCatalogsFiltered(
            stacCatalogs
                .filter((catalog) => textFilterRegEx.test(catalog.title) || textFilterRegEx.test(catalog.id.toString()))
                .sort((a, b) => {
                    if (sortBy === 'id') {
                        return sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
                    } else if (sortBy === 'title') {
                        return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
                    } else {
                        return 0;
                    }
                })
        );
    }, [stacCatalogs, textFilter, sortBy, sortOrder]);

    const onClickRowSTAC = {
        onClick: (_e, row: AttachedSTACCatalogDTO) => {
            store.dispatch(push(`/stac/${row.id}`));
        },
        onAuxClick: (_e, row: AttachedWmsServerDTO) => {
            const win = window.open(`/stac/${row.id}`, '_blank');
            if (!win) {
                win.focus();
            }
        },
    };

    if (!stacCatalogs?.length) {
        return <React.Fragment />;
    }

    return (
        <ProfileListingsGenericTable
            subTitle={`Total of ${stacCatalogs.length} STAC catalogs`}
            data={stacCatalogsFiltered}
            columns={COLS_STAC_CATALOGS}
            onClickRowEvent={onClickRowSTAC}
            divider
        />
    );
};

export default ProfileListingsStacCatalogs;
